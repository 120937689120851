import React from 'react';
import 'twin.macro';

import PageMeta from '../components/PageMeta';
import ResourceLink from '../components/ResourceLink';

const NotFoundPage = () => (
  <>
    <PageMeta title="404 Not found" />
    <h1>Page not found</h1>
    <p>
      <ResourceLink to="home">Click here</ResourceLink> to go home
    </p>
  </>
);

export default NotFoundPage;
